import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Intro from "../../elements/blocks/intro";
import TicketsMetrics from "../../elements/tickets/tickets-metrics";
import InformationFieldsMetrics from "../../elements/tickets/information-fields-metrics";
import TicketsTable from "../../elements/tickets/tickets-table";
import Nope from "../../elements/blocks/nope";
import Controls from "../../elements/blocks/controls";
import { useApiTenant } from "../../../api/api-tenant";
import { flatGroups } from "../../../helpers/groups";
import { useRoles } from "../../../services/roles";
import { useTenancy } from "../../../services/tenancy";
export default function CampaignGroup() {
    const { groupId } = useParams();
    return _jsx(CampaignGroupComponent, {}, groupId);
}
function CampaignGroupComponent() {
    var _a;
    const { t } = useTranslation();
    const { campaignId, groupId } = useParams();
    const api = useApiTenant();
    const roles = useRoles();
    const tenancy = useTenancy();
    const [campaign, setCampaign] = useState(null);
    const [statusCount, setStatusCount] = useState(null);
    const [priorityCount, setPriorityCount] = useState(null);
    const [group, setGroup] = useState(null);
    const [tab, setTab] = useState("tickets");
    const [groupBreadcrumbs, setGroupBreadcrumbs] = useState(null);
    const [, setIsCampaignLoading] = useState(true);
    const [isGroupLoading, setIsGroupLoading] = useState(true);
    const [campaignVisible, setCampaignVisible] = useState(false);
    const campaignVisibleRef = useRef(campaignVisible);
    const [groupVisible, setGroupVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [resetStatusAndPriorityCount, setResetStatusAndPriorityCount] = useState(false);
    const [range, setRange] = useState("none");
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [isChosenGlobalCustomRange, setIsChosenGlobalCustomRange] = useState(false);
    const [count, setCount] = useState(false);
    const [countDisabled, setCountDisabled] = useState(false);
    const [countIsLoading, setCountIsLoading] = useState(false);
    const getCampaign = () => {
        api.GET_campaign(campaignId, undefined, groupId)
            .then(response => {
            var _a, _b, _c, _d;
            if (roles.isAgent) {
                let related = [];
                let flatTenantGroups = [];
                if (roles.groupsTenant) {
                    flatTenantGroups = flatGroups(roles.groupsTenant, 0, []);
                }
                if (response.data.campaign.groups.length > 0) {
                    response.data.campaign.groups.map(group => {
                        flatTenantGroups.map(i => {
                            if (i.id === group.id) {
                                related.push(group);
                            }
                        });
                    });
                }
                if ((response.data.campaign.groups &&
                    response.data.campaign.active &&
                    ((_a = response.data.campaign.groups) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                    related.length > 0) ||
                    (response.data.campaign.active &&
                        ((_b = response.data.campaign.groups) === null || _b === void 0 ? void 0 : _b.length) === 0)) {
                    campaignVisibleRef.current = true;
                    setCampaignVisible(true);
                }
            }
            else {
                setCampaignVisible(true);
                campaignVisibleRef.current = true;
            }
            setCampaign(response.data.campaign);
            setIsCampaignLoading(false);
            if (roles.isAgent) {
                if (roles.groupsTenant) {
                    let flatTenantGroups = flatGroups(roles.groupsTenant, 0, []);
                    if (flatTenantGroups.find(gr => gr.id === response.data.campaign.filter_group.id)) {
                        setGroupVisible(true);
                        setVisible(true);
                        if (campaignVisibleRef.current) {
                            if (((_c = response.data.campaign.filter_group) === null || _c === void 0 ? void 0 : _c.parent_groups) &&
                                groupBreadcrumbs === null) {
                                let groupParents = [];
                                groupParents = flatGroups([response.data.campaign.filter_group.parent_groups], 0, groupParents).reverse();
                                let groupBreadcrumbs;
                                groupBreadcrumbs = groupParents
                                    .map(group => group.name)
                                    .join(" › ");
                                groupBreadcrumbs =
                                    groupBreadcrumbs +
                                        " › " +
                                        response.data.campaign.filter_group.name;
                                setGroupBreadcrumbs(groupBreadcrumbs);
                            }
                            else {
                                setGroupBreadcrumbs(response.data.campaign.filter_group.name);
                            }
                        }
                    }
                }
            }
            else {
                setGroupVisible(true);
                setVisible(true);
                if (groupBreadcrumbs === null) {
                    if ((_d = response.data.campaign.filter_group) === null || _d === void 0 ? void 0 : _d.parent_groups) {
                        let groupParents = [];
                        groupParents = flatGroups([response.data.campaign.filter_group.parent_groups], 0, groupParents).reverse();
                        let groupBreadcrumbs;
                        groupBreadcrumbs = groupParents.map(group => group.name).join(" › ");
                        groupBreadcrumbs =
                            groupBreadcrumbs + " › " + response.data.campaign.filter_group.name;
                        setGroupBreadcrumbs(groupBreadcrumbs);
                    }
                    else {
                        setGroupBreadcrumbs(response.data.campaign.filter_group.name);
                    }
                }
            }
            setGroup(response.data.campaign.filter_group);
            setIsGroupLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsCampaignLoading(false);
                setIsGroupLoading(false);
            }
        });
    };
    const getStatusAndPriorityCount = () => {
        setCountDisabled(true);
        setCountIsLoading(true);
        api.GET_campaign(campaignId, 1, groupId)
            .then(response => {
            setStatusCount(response.data.campaign.ticket_statuses);
            setPriorityCount(response.data.campaign.ticket_priorities);
            setCount(false);
            setCountDisabled(false);
            setCountIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
            setCount(false);
            setCountDisabled(false);
            setCountIsLoading(false);
        });
    };
    useEffect(() => {
        if (count) {
            getStatusAndPriorityCount();
        }
    }, [count]);
    useEffect(() => {
        setIsCampaignLoading(true);
        setIsGroupLoading(true);
        getCampaign();
        return () => api.cancel();
    }, [groupId]);
    useEffect(() => {
        if (!resetStatusAndPriorityCount)
            return;
        setStatusCount(null);
        setPriorityCount(null);
        getStatusAndPriorityCount();
        setResetStatusAndPriorityCount(false);
    }, [resetStatusAndPriorityCount]);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: group && campaignVisibleRef.current && groupVisible
                    ? group.name
                    : t("queues.queue") + " #" + groupId, breadcrumbs: [
                    {
                        name: t("campaigns.campaigns"),
                        link: "/campaigns",
                    },
                    {
                        name: campaign && campaignVisibleRef.current
                            ? campaign.name
                            : t("campaigns.campaign"),
                        link: "/campaigns/" + campaignId,
                    },
                    {
                        name: t("queues.queues"),
                        link: "/campaigns/" + campaignId + "/groups",
                    },
                    {
                        name: groupBreadcrumbs ? groupBreadcrumbs : t("queues.queue"),
                        link: "/campaigns/" + campaignId + "/groups/" + groupId,
                    },
                ], button: campaign
                    ? process.env.APP_ID === "mcd" &&
                        roles.isAgent &&
                        ((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.domain) !== "naljepnice"
                        ? undefined
                        : {
                            label: t("tickets.new-ticket"),
                            link: "/campaigns/" + campaignId + "/new-ticket",
                            color: "secondary",
                            type: "new",
                        }
                    : undefined, loading: isGroupLoading }), isGroupLoading ? (_jsx(Fragment, {})) : campaign ? (_jsx(Fragment, { children: group ? (_jsxs(Fragment, { children: [(roles.isAdmin || roles.isManager) && (_jsx(Controls, { tabs: [
                                {
                                    label: t("tickets.tickets"),
                                    value: "tickets",
                                },
                                {
                                    label: t("campaigns.reports"),
                                    value: "reports",
                                },
                            ], tab: tab, setTab: setTab, enableDateChanging: tab === "reports", range: range ? range : "none", setRange: setRange, startDate: startDate, setStartDate: setStartDate, startTime: startTime, setStartTime: setStartTime, endDate: endDate, setEndDate: setEndDate, endTime: endTime, setEndTime: setEndTime, setIsChosenGlobalCustomRange: setIsChosenGlobalCustomRange, setCount: tab === "tickets" ? setCount : undefined, countDisabled: countDisabled, countIsLoading: countIsLoading })), tab === "tickets" ? (visible ? (_jsx(Fragment, { children: _jsx(TicketsTable, { type: "group", campaign: campaign, group: group, setResetStatusAndPriorityCount: setResetStatusAndPriorityCount, user: campaign && campaign.type === "contract"
                                    ? roles.userTenant
                                        ? roles.userTenant
                                        : undefined
                                    : undefined, statusCount: statusCount, priorityCount: priorityCount, setCountDisabled: setCountDisabled }) })) : (_jsx(Nope, { title: t("errors.unauthorized"), caption: t("errors.no-permission") }))) : (_jsxs(Fragment, { children: [_jsx(TicketsMetrics, { type: "campaign_group", group_id: group.id, campaign: campaign, globalRange: range, setGlobalRange: setRange, globalStartDate: startDate, globalStartTime: startTime, globalEndDate: endDate, globalEndTime: endTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange }), _jsx(InformationFieldsMetrics, { type: "campaign_group", group_id: group.id, campaign: campaign, information_type: "choice", globalRange: range, setGlobalRange: setRange, globalStartDate: startDate, globalStartTime: startTime, globalEndDate: endDate, globalEndTime: endTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange }), _jsx(InformationFieldsMetrics, { type: "campaign_group", group_id: group.id, campaign: campaign, information_type: "field", globalRange: range, setGlobalRange: setRange, globalStartDate: startDate, globalStartTime: startTime, globalEndDate: endDate, globalEndTime: endTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange })] }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("errors.queues.not-found") })) })) : (_jsx(Nope, { title: t("errors.404"), caption: t("campaigns.errors.not-found") }))] }));
}
